import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h2>How it Works</h2>

    <div className="container">
      <p className="g-p">
        One of my strongest beliefs is that serving people fresh, handmade food is one of the most intimate ways to show that you care for them.
        With <strong>Lavoretto</strong>, I'd like to take my cooking from my kitchen table to yours.
      </p>

      <p className="g-p">
        I want to try sharing my love for feeding people in a personal way, on a
        grander scale. This will be a sort of experiment. Every Sunday evening I
        make fresh pasta and I'll bring it to you on Monday myself.
      </p>
    </div>

    <div className="container">
      <p><strong>Once your order is placed</strong><br/>I will follow up to confirm your order.</p>
      <p><strong>On Sunday evening</strong><br/>Preparations will take place and I will confirm that you can pick up on Monday.</p>
      <p><strong>On Monday at 6pm</strong><br/>Pickup will be available at 545 King Street West</p>
    </div>

    {/* <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default AboutPage
